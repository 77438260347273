import { NavLink } from 'react-router-dom'

import i from '~/i18n'

export const advertisersAudienceTableSchema: TableSchema<AdvertiserPublisherLink> =
  {
    name: {
      title: i.t('rm:Advertisers'),
      render: rowData => (
        <NavLink
          to={`/retail-media/audiences/advertiser/${rowData.advertiser_id}`}
        >
          <strong>{rowData.advertiser_name}</strong>
        </NavLink>
      ),
      showTotal: true
    }
  }
