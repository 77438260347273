import { Link } from 'react-router-dom'

import { ButtonNavLink } from '~/components/Buttons'
import { LogoStore } from '~/components/LogoStore'
import RangeSlider from '~/components/RangeSlider'
import Status from '~/components/Status'
import { Badge } from '~/components/UI/Badge'
import { formatDateToString, formatNumberWithK } from '~/helpers'
import i from '~/i18n'

import { audienceAttributeLabels } from '../lib/attributes'
import { audienceStatus } from '../lib/status'

export const schameAudiencesPublisher: TableSchema<AudienceDataList> = {
  name: {
    title: 'Nome da audiência',
    render: data => (
      <div className="d-flex align-items-center gap-1">
        <LogoStore
          alt={data.advertiser_name}
          linkLogo={data.advertiser_account_logo}
          backgroundLogo={data.advertiser_account_theme.primary_color}
          size="small"
        />

        <div className="d-flex flex-column justify-content-center lh-sm">
          <Link
            to={`/retail-media/audiences/view/${data.id}?callback_url=${window.location.pathname}`}
          >
            {data.name}
          </Link>

          <small>{data.advertiser_name}</small>
        </div>
      </div>
    )
  },
  status: {
    title: 'Status',
    render: data => (
      <Status
        status={audienceStatus?.[data.status] || { label: data.status }}
      />
    )
  },
  reach_range: {
    title: 'Alcance potencial',
    render: data => (
      <div>
        <div className="value fs-5">
          {formatNumberWithK({ number: data?.estimated_audience })}
        </div>
        <RangeSlider
          min={0}
          max={100}
          value={data?.reach_range || 0}
          readOnly
          size="small"
        />
      </div>
    )
  },
  segmentations: {
    title: i.t('rm:audience.attributes'),
    render: data => (
      <div className="d-flex gap-1">
        {data?.segmentations?.map(segmentation => (
          <Badge
            key={segmentation.id}
            text={audienceAttributeLabels[segmentation.feature_key]}
          />
        ))}
      </div>
    )
  },
  createdAt: {
    title: i.t('c:terms.created_at'),
    render: data => formatDateToString(data.created_at)
  },
  action: {
    render: data => (
      <ButtonNavLink
        size="small"
        to={`/retail-media/audiences/view/${data.id}?callback_url=${window.location.pathname}`}
      >
        {i.t('rm:audience.reviewAudience')}
      </ButtonNavLink>
    )
  }
}
