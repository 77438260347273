import { useCallback, useEffect, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import AlertInfo from '~/components/AlertInfo'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Table from '~/components/Table'
import { history } from '~/helpers'
import { handleSortDirection } from '~/helpers/sortData'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { listAudiencesService } from '~/modules/retailMedia/services/audience'

import AudienceTabs from '../elements/AudienceTabs'
import HeaderAudiences from '../elements/HeaderAudiences'
import { schameAudiencesPublisher } from './schema'

const ReviewAudience: React.FC = () => {
  const { t } = useAPPTranslation()

  /**
   * Handle request
   */
  const searchParams = useQueryParams()

  const page = Number(searchParams.get('page')) || 1
  const quantity = Number(searchParams.get('quantity')) || 50
  const billing_type = searchParams.get('billing_type') as AudienceBillingType
  const sort_direction = searchParams.get('sort_direction') as SortDirection
  const sort_key = searchParams.get('sort_key')

  const params = useMemo(() => {
    const data: ListAudienceQueryParams = {
      page,
      quantity,
      status: 'awaiting_approval',
      billing_type,
      order_direction: sort_direction,
      order_by: sort_key
    }

    return data
  }, [page, quantity, billing_type, sort_direction, sort_key])

  const { isPending, isFetching, data, error } = useQuery({
    queryKey: ['list-audience', ...Object.values(params)],
    queryFn: async () => {
      const { data } = await listAudiencesService(params)

      return data
    }
  })

  const [AlertOnError, setError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  useEffect(() => {
    if (error) setError(error)
  }, [error, setError])

  /**
   * Handle Pagination
   */
  const handlePagination = useCallback(
    (value: number) => {
      searchParams.set('page', String(value))
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      searchParams.set('quantity', String(value.value))
      searchParams.set('page', '1')
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )
  /**
   * Sort list
   */
  const handleSortList = useCallback(
    (data: OnSortProps) => {
      const selectedKey = data.key
      const sortInitialDirection = data?.sortInitialDirection

      const response = handleSortDirection({
        selectedKey,
        currentSortDirection: sort_direction,
        currentSortKey: sort_key,
        sortInitialDirection
      })

      searchParams.set('sort_direction', response.sortDirection)
      searchParams.set('sort_key', response.sortKey)

      history.push({ search: searchParams.toString() })
    },
    [searchParams, sort_direction, sort_key]
  )

  /**
   * <Handle advertiser view>
   */

  return (
    <>
      <HeaderAudiences pageTitle={t('rm:audience.review.pageTitle')} />

      <>
        <AudienceTabs />

        <Loading status={isPending} />

        <Loading status={!isPending && isFetching} onlyLine />

        <AlertOnError />

        {!!data?.total && (
          <Table
            schema={schameAudiencesPublisher}
            data={data?.data}
            sideBorder
            freeze="first-row-and-column"
            // sort settings
            activeSortDirection={sort_direction}
            activeSortKey={sort_key}
            onSort={handleSortList}
          />
        )}

        {data?.total === 0 && data && (
          <AlertInfo template="warning" text={t('c:table.emptyFiltered')} />
        )}

        <ListPagination
          total={data?.total}
          label={t('rm:audience', { count: data?.total })}
          currentPage={page}
          itemsPerPage={quantity}
          menuPlacement="top"
          onClickPagination={handlePagination}
          onItemsPerPageChange={handleItemsPerPageChange}
          customOptions={[5, 10, 20, 50]}
        />
      </>
    </>
  )
}

export default ReviewAudience
