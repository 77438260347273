import { useCallback, useEffect, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import AlertInfo from '~/components/AlertInfo'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import Table from '~/components/Table'
import { getWhiteLabelPublisherId, history, isReadOnly } from '~/helpers'
import { handleSortDirection } from '~/helpers/sortData'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import useQueryParams from '~/hooks/useQuery'
import { useSidePanel } from '~/hooks/useSidePanel'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { listAudienceEnrichedService } from '~/modules/retailMedia/services/audience-enriched'

import AudienceTabs from '../elements/AudienceTabs'
import HeaderAudiences from '../elements/HeaderAudiences'
import EditAudienceEnriched from './EditAudienceEnriched'
import AudienceEnrichedFilters from './Filters'
import { schameAudiencesPublisher } from './schema'

const AudiencesEnriched: React.FC = () => {
  const { t } = useAPPTranslation()

  /**
   * Handle request
   */
  const searchParams = useQueryParams()
  const label = searchParams.get('name')
  const key = searchParams.get('key')
  const page = Number(searchParams.get('page')) || 1
  const quantity = Number(searchParams.get('quantity')) || 50
  const publisherId =
    (searchParams.get('rmid') as BrandedType<string, 'PublisherId'>) || null
  const sort_direction = searchParams.get('sort_direction') as SortDirection
  const sort_key = searchParams.get('sort_key')
  // check if searchParams.get('edit_id') is number
  const editId =
    (Number(searchParams.get('edit_id')) as AudienceEnriched['id']) || null

  const { isWhiteLabel } = useAppInfo()

  const selectedPublisherId = useMemo(
    () => (isWhiteLabel ? getWhiteLabelPublisherId() : publisherId),
    [isWhiteLabel, publisherId]
  )

  const params = useMemo(() => {
    const data: AudienceEnrichedQueryParams = {
      page,
      quantity,
      label,
      key,
      publisher_id: selectedPublisherId,
      order_direction: sort_direction,
      order_by: sort_key
    }

    return data
  }, [
    page,
    quantity,
    label,
    key,
    selectedPublisherId,
    sort_direction,
    sort_key
  ])

  const queryKey = useMemo(
    () => ['list-enriched-audiences', ...Object.values(params).map(String)],
    [params]
  )

  const { isPending, isFetching, data, error } = useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await listAudienceEnrichedService(params)

      return data
    }
  })

  const [AlertOnError, setError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  useEffect(() => {
    if (error) setError(error)
  }, [error, setError])

  /**
   * Handle Pagination
   */
  const handlePagination = useCallback(
    (value: number) => {
      searchParams.set('page', String(value))
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      searchParams.set('quantity', String(value.value))
      searchParams.set('page', '1')
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )
  /**
   * Sort list
   */
  const handleSortList = useCallback(
    (data: OnSortProps) => {
      const selectedKey = data.key
      const sortInitialDirection = data?.sortInitialDirection

      const response = handleSortDirection({
        selectedKey,
        currentSortDirection: sort_direction,
        currentSortKey: sort_key,
        sortInitialDirection
      })

      searchParams.set('sort_direction', response.sortDirection)
      searchParams.set('sort_key', response.sortKey)

      history.push({ search: searchParams.toString() })
    },
    [searchParams, sort_direction, sort_key]
  )

  /**
   * Handle edit
   */

  const { addSidePanel, removeSidePanel } = useSidePanel()

  const callbackRemoveSidePanel = useCallback(() => {
    searchParams.delete('edit_id')
    history.push({ search: searchParams.toString() })
  }, [searchParams])

  const removeSidePanelWithCallback = useCallback(() => {
    removeSidePanel({
      callback: callbackRemoveSidePanel
    })
  }, [callbackRemoveSidePanel, removeSidePanel])

  useEffect(() => {
    if (editId && !isReadOnly()) {
      addSidePanel({
        title: t('rm:audience.audienceEnriched.edit.titlePanel'),
        type: 'content',
        content: (
          <EditAudienceEnriched
            id={editId}
            onCancel={removeSidePanelWithCallback}
            listQueryKey={queryKey}
          />
        ),
        callbackRemove: callbackRemoveSidePanel
      })
    }

    if (!editId) {
      removeSidePanel()
    }
  }, [
    editId,
    searchParams,
    addSidePanel,
    callbackRemoveSidePanel,
    removeSidePanel,
    removeSidePanelWithCallback,
    t,
    queryKey
  ])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        removeSidePanelWithCallback()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [removeSidePanelWithCallback])

  /**
   * END - Handle edit
   */

  return (
    <>
      <PageTitle title={t('rm:audience.audienceEnriched.pageTitle')} />

      <HeaderAudiences
        pageTitle={t('rm:audience.audienceEnriched.pageTitle')}
      />

      <AudienceTabs />

      <AudienceEnrichedFilters />

      <Loading status={isPending} />

      <Loading status={!isPending && isFetching} onlyLine />

      <AlertOnError />

      {!!data?.total && (
        <Table
          schema={schameAudiencesPublisher}
          data={data?.data}
          sideBorder
          // sort settings
          activeSortDirection={sort_direction}
          activeSortKey={sort_key}
          onSort={handleSortList}
        />
      )}

      {data?.total === 0 && data && (
        <AlertInfo template="warning" text={t('c:table.emptyFiltered')} />
      )}

      <ListPagination
        total={data?.total}
        label={t('rm:audience.audienceEnriched', { count: data?.total })}
        currentPage={page}
        itemsPerPage={quantity}
        menuPlacement="top"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
        customOptions={[5, 10, 20, 50]}
      />
    </>
  )
}

export default AudiencesEnriched
