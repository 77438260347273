import { useQuery } from '@tanstack/react-query'

import Loading from '~/components/Loading'
import Table from '~/components/Table'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import AdvertisersFilters from '~/modules/retailMedia/components/_tables/AdvertiserFilters'
import {
  listAdvertisersService,
  listAdvertisersServiceKey
} from '~/modules/retailMedia/services/advertiser-publisher'

import AudienceTabs from '../elements/AudienceTabs'
import HeaderAudiences from '../elements/HeaderAudiences'
import { advertisersAudienceTableSchema } from './schema'

const AdvertisersList: React.FC = () => {
  const { t } = useAPPTranslation()

  const params = useQueryParams()
  const page = Number(params.get('page')) || 1
  const quantity = Number(params.get('quantity')) || 50
  const advertiser_name = params.get('advertiser_name')
  const seller_id = params.get('seller_id') as BrandedType<string, 'SellerId'>
  const tag_id = params.get('tag_id') as PublisherTagId

  const { isPending, isFetching, data, error } = useQuery({
    queryKey: [
      listAdvertisersServiceKey,
      page,
      quantity,
      advertiser_name,
      seller_id,
      tag_id
    ],
    queryFn: async () => {
      const payload = {
        page,
        quantity,
        advertiser_name,
        seller_id,
        tag_id
      }

      const response = await listAdvertisersService(payload)

      return response.data
    }
  })

  return (
    <>
      <HeaderAudiences pageTitle={t('rm:audience.home.pageTitle')} />

      <AudienceTabs />

      <AdvertisersFilters hideCreateButton={false} />

      <Table
        smallerRowPadding
        schema={advertisersAudienceTableSchema}
        data={data?.data?.advertisers}
        total={data?.total}
        sideBorder
      />

      <Loading status={isPending} />
    </>
  )
}

export default AdvertisersList
