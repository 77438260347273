import i from '~/i18n'

const audienceStatuses: AudienceStatus[] = [
  'awaiting_approval',
  'awaiting_processing',
  'approved',
  'rejected'
]

export const audienceStatus: AudienceStatuses = {
  awaiting_approval: {
    type: 'warning',
    label: i.t(`rm:audience.status.awaiting_approval`)
  },
  awaiting_processing: {
    type: 'success_alert',
    label: i.t(`rm:audience.status.awaiting_processing`)
  },
  approved: {
    type: 'success',
    label: i.t(`rm:audience.status.approved`)
  },
  rejected: {
    type: 'danger',
    label: i.t(`rm:audience.status.rejected`)
  }
}

export const audienceStatusOptions = audienceStatuses.map(item => ({
  value: item,
  label: audienceStatus?.[item]?.label || item
}))
