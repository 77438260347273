import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { isAdvertiser, isPublisher } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import PublishersFieldset from '~/modules/retailMedia/components/SelectPublishersV2'

import AudienceInnerTabs from '../elements/AudienceInnerTabs'
import AudienceTable from '../elements/AudienceTable'
import HeaderAudiences from '../elements/HeaderAudiences'
import AudienceFilters from './Filters'

/**
 * Página para listar audiências relacionadas ao anunciantes.
 * Por padrão, listará as audiências criadas pelo anunciante.
 * Tem um segundo modo para listar as audiências disponíveis para o anunciante.
 * Para usá-lo, basta adicionar o modo `available` na url.
 */

const ListAdvertiserAudiences: React.FC = () => {
  const { t } = useAPPTranslation()

  /**
   * Handle request
   */
  const searchParams = useQueryParams()
  const name = searchParams.get('name')
  const page = Number(searchParams.get('page')) || 1
  const quantity = Number(searchParams.get('quantity')) || 50
  const status = searchParams.get('status') as AudienceStatus
  const billing_type = searchParams.get('billing_type') as AudienceBillingType
  const visibility = searchParams.get('visibility') as 'public' | 'private'
  const sort_direction = searchParams.get('sort_direction') as SortDirection
  const sort_key = searchParams.get('sort_key')

  const { id, mode } = useParams<{ id: AdvertiserId; mode?: 'available' }>()

  const { isWhiteLabel } = useAppInfo()

  const params = useMemo(() => {
    const data: ListAudienceQueryParams = {
      page,
      quantity,
      name,
      status,
      billing_type,
      audience_scope: 'advertiser',
      advertiser_id: id,
      order_direction: sort_direction,
      order_by: sort_key
    }

    if (mode === 'available') {
      data.audience_scope = 'publisher'
    }

    if (visibility) {
      data.is_global = visibility === 'public'
    }

    return data
  }, [
    page,
    quantity,
    name,
    status,
    billing_type,
    id,
    sort_direction,
    sort_key,
    mode,
    visibility
  ])

  /**
   * <Handle advertiser view>
   */

  // Handle query params

  const showPublisherFieldset = useMemo(
    () => !isWhiteLabel && isAdvertiser(),
    [isWhiteLabel]
  )

  const showContent = useMemo(
    () => !!(isWhiteLabel || isPublisher() || (!isWhiteLabel && id)),
    [isWhiteLabel, id]
  )

  return (
    <>
      <HeaderAudiences pageTitle={t('rm:audience.home.pageTitle')} />

      {!showContent && showPublisherFieldset && <PublishersFieldset />}

      {showContent && (
        <>
          <AudienceInnerTabs />

          <AudienceFilters />

          <AudienceTable params={params} />
        </>
      )}
    </>
  )
}

export default ListAdvertiserAudiences
