import { FiAlertTriangle } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'

import { ButtonNavLink } from '~/components/Buttons'
import { Badge } from '~/components/UI/Badge'
import { formatDateToString } from '~/helpers'
import i from '~/i18n'

// add id as edit_id on current url and kepp current params
// return string to use in NavLink
const formatUrl = (id: BrandedType<number, 'AudienceEnrichedId'>) => {
  const url = new URL(window.location.href)
  url.searchParams.set('edit_id', String(id))
  return url.toString()
}

export const schameAudiencesPublisher: TableSchema<AudienceEnriched> = {
  key: {
    title: i.t('rm:audience.audienceEnriched_other'),
    // sortKey: 'label',
    render: data => (
      <NavLink to={formatUrl(data.id)} className="text-decoration-none">
        <Badge size="small">{data.key}</Badge>

        <div className="name text-decoration-underline">
          {data.label ? (
            <strong>{data.label}</strong>
          ) : (
            <Badge
              template="warning"
              text="Sem nome definido"
              iconLeft={<FiAlertTriangle />}
            />
          )}
        </div>

        {data?.description && (
          <p className="fs-6 text-neutral-low-light m-0">{data.description}</p>
        )}
      </NavLink>
    )
  },
  createdAt: {
    title: i.t('c:terms.created_at'),
    render: data => formatDateToString(data.created_at, 'DD/MM/YYYY')
  },
  updatedAt: {
    title: i.t('c:terms.updated_at'),
    render: data => formatDateToString(data.updated_at, 'DD/MM/YYYY')
  },
  action: {
    render: data => (
      <ButtonNavLink
        to={formatUrl(data.id)}
        size="xsmall"
        customWidth="auto"
        text={i.t('c:actions.edit')}
        template="light"
      />
    )
  }
}
