import React, { useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import { debounce } from 'lodash'

import Input from '~/components/Form/Input'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import FilterAudienceStatus from '~/modules/retailMedia/components/Filters/FilterAudienceStatus'

// import { Container } from './styles';

const AudienceFilters: React.FC = () => {
  const [innerLoading, setInnerLoading] = useState(false)

  const { t } = useAPPTranslation()

  const params = useQueryParams()
  const page = Number(params.get('page')) || 1
  const quantity = Number(params.get('quantity')) || 50
  const name = params.get('name')

  // Filter
  const executeDebounceFunc = (func: () => void) => func()

  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  const handleFilter = (data: OnChangeInputData) => {
    const { name, value } = data

    if (!value) {
      const newParams = new URLSearchParams(params.toString())
      newParams.delete(name)
      history.push({ search: newParams.toString() })
      setInnerLoading(false)

      return
    }

    params.set(name, String(value))
    params.set('page', '1')
    history.push({ search: params.toString() })

    setInnerLoading(false)
  }

  const handleChange = (data: OnChangeInputData) => {
    setInnerLoading(true)
    handleDebounce(() => handleFilter(data))
  }

  return (
    <form className="d-flex gap-2 mb-2">
      <Input
        name="name"
        onChange={handleChange}
        defaultValue={name}
        icon={FiSearch}
        placeholder={t('rm:filterByAudienceName')}
        type="search"
        loading={innerLoading}
      />

      <FilterAudienceStatus />
    </form>
  )
}

export default AudienceFilters
