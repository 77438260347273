import i from '~/i18n'

/**
 * Usado para montar select de atributos de audiência
 * src/modules/retailMedia/pages/audiences/create/elements/AttributeSelect/fieldKeyOptions.ts
 */
export const attributesFieldKeys: AudienceAttributeFeatureKey[] = [
  'AGE',
  'CITY',
  'STATE',
  'GENDER',
  'NBO_CATEGORIES',
  'AUDIENCE'
]

export const audienceAttributeLabels = {
  AGE: i.t('rm:audience.attribute.fieldKey.age'),
  CITY: i.t('rm:audience.attribute.fieldKey.city'),
  STATE: i.t('rm:audience.attribute.fieldKey.state'),
  GENDER: i.t('rm:audience.attribute.fieldKey.gender'),
  NBO_CATEGORIES: i.t('rm:audience.attribute.fieldKey.nbo_categories'),
  AUDIENCE: i.t('rm:audience.attribute.fieldKey.audience')
}
