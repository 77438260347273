import { useMemo } from 'react'

import { isAdvertiser, isPublisher } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import PublishersFieldset from '~/modules/retailMedia/components/SelectPublishersV2'

import AudienceTable from '../elements/AudienceTable'
import AudienceTabs from '../elements/AudienceTabs'
import HeaderAudiences from '../elements/HeaderAudiences'
import AudienceFilters from './Filters'

/**
 * Página para listar audiências.
 * Por padrão, listará usando o escopo da conta logada `getRetailMediaAccountType()`.
 * Pode receber outro scope por url params. /:scope?
 */

const ListAudiences: React.FC = () => {
  const { t } = useAPPTranslation()

  /**
   * Handle request
   */
  const searchParams = useQueryParams()
  const name = searchParams.get('name')
  const page = Number(searchParams.get('page')) || 1
  const quantity = Number(searchParams.get('quantity')) || 50
  const id = (searchParams.get('rmid') as PublisherId) || null
  const status = searchParams.get('status') as AudienceStatus
  const billing_type = searchParams.get('billing_type') as AudienceBillingType
  const visibility = searchParams.get('visibility') as 'public' | 'private'
  const sort_direction = searchParams.get('sort_direction') as SortDirection
  const sort_key = searchParams.get('sort_key')

  const { isWhiteLabel } = useAppInfo()

  const params = useMemo(() => {
    const data: ListAudienceQueryParams = {
      page,
      quantity,
      name,
      status,
      billing_type,
      audience_scope: 'publisher',
      order_direction: sort_direction,
      order_by: sort_key
    }

    if (!isWhiteLabel && !!id) {
      data.publisher_id = id as PublisherId
    }

    if (visibility) {
      data.is_global = visibility === 'public'
    }

    return data
  }, [
    page,
    quantity,
    name,
    status,
    billing_type,
    sort_direction,
    sort_key,
    isWhiteLabel,
    id,
    visibility
  ])

  /**
   * <Handle advertiser view>
   */

  // Handle query params

  const showPublisherFieldset = useMemo(
    () => !isWhiteLabel && isAdvertiser(),
    [isWhiteLabel]
  )

  const showContent = useMemo(
    () => !!(isWhiteLabel || isPublisher() || (!isWhiteLabel && id)),
    [isWhiteLabel, id]
  )

  return (
    <>
      <HeaderAudiences pageTitle={t('rm:audience.home.pageTitle')} />

      {!showContent && showPublisherFieldset && <PublishersFieldset />}

      {showContent && (
        <>
          <AudienceTabs />

          <AudienceFilters />

          <AudienceTable params={params} />
        </>
      )}
    </>
  )
}

export default ListAudiences
