import { useCallback, useRef } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation } from '@tanstack/react-query'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { history, isAdvertiser, isPublisher } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import PublishersFieldset from '~/modules/retailMedia/components/SelectPublishersV2'
import { createAudienceService } from '~/modules/retailMedia/services/audience'

import AudienceCreateAdvertiser from './elements/FieldsetAdvertiser'
import AudienceCreateDetails from './elements/FieldsetDetails'
import AudienceCreatePrice from './elements/FieldsetPrice'
import AudienceCreateSegmentationRules from './elements/SegmentationRules'

const AudienceCreatePage: React.FC = () => {
  const { t, tToasts } = useAPPTranslation()

  const formRef = useRef<FormHandles>(null)

  const queryParams = useQueryParams()
  const publisherId = queryParams.get('rmid') as PublisherId
  const callbackUrl = queryParams.get('callback_url')

  const { isWhiteLabel, lengthCentsCurrency } = useAppInfo()

  const [AlertOnError, setError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  const { mutate: handleCreateAudience } = useMutation({
    mutationKey: ['audienceCreate'],
    mutationFn: createAudienceService,
    onSuccess: response => {
      history.push(`/retail-media/audiences/view/${response.data.id}`)

      tToasts({
        prefix: 'rm:audience.create.toast.success',
        toastFunction: toastr.success
      })
    },
    onError: error => {
      tToasts({
        prefix: 'c:messages.errorOccurred',
        toastFunction: toastr.error
      })

      setError(error)
    }
  })

  const handleSubmit = (formData: AudienceCreateFormData) => {
    // Handle segmentations
    const validSegmentations = Object.values(formData.rules.add).filter(
      item => item?.feature_key && item?.feature_values?.length > 0
    )

    if (validSegmentations.length === 0) return

    const segmentations = validSegmentations.map(item => ({
      feature_key: item.feature_key,
      feature_values: item.feature_values.map(value => value.value)
    }))

    // Handle price
    let price = null
    if (formData.priceCheck === 'fixed') {
      const priceRaw = formData?.price?.numAsString

      if (!priceRaw) {
        console.error('Price is required')

        tToasts({
          prefix: 'c:messages.errorOccurred',
          toastFunction: toastr.error
        })

        return
      }

      price = lengthCentsCurrency ? Number(priceRaw) / 100 : Number(priceRaw)
    }

    // Handle advertisers
    let advertiser_ids = null
    if (formData.advertiserCheck === 'private') {
      advertiser_ids = formData.advertiser_ids.split(',') as BrandedType<
        string,
        'AdvertiserId'
      >[]
    }

    const body: AudienceCreateRequestBody = {
      name: formData.name,
      description: formData.description,
      segmentations,
      price,
      advertiser_ids
    }

    // Handle publisher
    if (publisherId) {
      body.publisher_id = publisherId
    }

    handleCreateAudience(body)
  }

  //Handle initial data

  const initialData: AudienceCreateInitialData = {
    priceCheck: 'free',
    price: 0,
    advertiserCheck: 'global'
  }

  // handle publisher change

  const handleChangePublisher = useCallback(
    (data: { value: PublisherId; label: string }) => {
      if (data.value !== publisherId) {
        formRef.current?.reset()
      }
    },
    [publisherId]
  )
  return (
    <>
      <PageTitle title={t('rm:audience.new')} />

      <HeaderPages
        title={t('rm:audience.new')}
        backTo={callbackUrl || '/retail-media/audiences'}
        labelBreadcrumb={t('rm:audience.backTo')}
      />

      {/* @ts-expect-error: Unform Form component does not have proper TypeScript definitions */}
      <Form onSubmit={handleSubmit} initialData={initialData} ref={formRef}>
        {isAdvertiser() && !isWhiteLabel && (
          <PublishersFieldset onChange={handleChangePublisher} />
        )}

        {(!!publisherId || isPublisher() || isWhiteLabel) && (
          <>
            <AudienceCreateDetails />

            <AudienceCreateSegmentationRules key={publisherId} />

            <AudienceCreatePrice />

            {isPublisher() && <AudienceCreateAdvertiser />}

            <AlertOnError />

            <div className="form-default__buttons">
              <Button
                type="submit"
                text={t('rm:audience.create.submitButton')}
                // loading={loading}
                size="large"
                template="success"
                // disabled={!formIsReadyToSubmit}
              />
            </div>
          </>
        )}
      </Form>
    </>
  )
}

export default AudienceCreatePage
