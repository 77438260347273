import { useEffect, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { FiHelpCircle } from 'react-icons/fi'
import { TbAlertHexagonFilled } from 'react-icons/tb'
import { Link, useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { AlertConfirmation } from '~/components/AlertConfirmation'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import { LogoStore } from '~/components/LogoStore'
import PageTitle from '~/components/PageTitle'
import Panel from '~/components/Panel'
import RangeSlider from '~/components/RangeSlider'
import Status from '~/components/Status'
import Table from '~/components/Table'
import Tooltip from '~/components/Tooltip'
import { Badge } from '~/components/UI/Badge'
import {
  formatDateToString,
  formatMoneyV2,
  formatNumberWithDots,
  isAdvertiser,
  isPublisher
} from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { getAudienceService } from '~/modules/retailMedia/services/audience'

import HeaderAudiences from '../elements/HeaderAudiences'
import { audienceAttributeLabels } from '../lib/attributes'
import { audienceStatus } from '../lib/status'
import { CampaignsByAudience } from './elements/Campaigns'
import HeaderAudienceView from './elements/Header'
import SectionAudienceReview from './elements/SectionReview'

const AudienceViewPage: React.FC = () => {
  const { t, tCapitalize: tc } = useAPPTranslation()

  const { audienceId: id } = useParams()
  const audienceId = Number(id) as AudienceId

  /**
   * <request audience>
   */
  const { data, error, isPending } = useQuery({
    queryKey: ['audience', 'view', audienceId],
    queryFn: async () => {
      const response = await getAudienceService(audienceId)
      return response.data
    }
  })

  const [AlertOnError, setError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  useEffect(() => {
    if (error) setError(error)
  }, [error, setError])

  /*** </request audience > */

  /**
   * <request account info>
   */

  const pageTitle = useMemo(
    () => `${tc('rm:audience_one')} - ${data?.name || t('c:words.loading')}`,
    [data?.name, t, tc]
  )

  return (
    <>
      <PageTitle title={pageTitle} />

      <HeaderAudiences
        pageTitle={t('rm:audience.home.pageTitle')}
        innerId={isPublisher() ? data?.advertiser_id : data?.publisher_id}
      />

      {/* <HeaderPages
        title={tc('rm:audience_one')}
        backTo={callbackUrl || '/retail-media/audiences'}
        labelBreadcrumb={t('rm:audience.backTo')}
      /> */}

      <Loading status={isPending} />

      <AlertOnError />

      {data && (
        <>
          <Panel>
            <HeaderAudienceView data={data} />

            <section>
              <article className="content d-flex gap-2">
                <strong>Status:</strong>
                <Status
                  status={
                    audienceStatus?.[data.status] || { label: data.status }
                  }
                />
              </article>

              {data.status === 'rejected' && (
                <AlertConfirmation
                  className="mt-2"
                  title={t('rm:audience.rejected')}
                  description={t('rm:audience.rejected.description')}
                  icon={<TbAlertHexagonFilled />}
                  template="danger"
                >
                  <section className="d-flex flex-column align-items-start gap-1 mt-1">
                    <article
                      className=" p-2 lh-1 border border-danger rounded bg-white"
                      style={
                        { '--bs-bg-opacity': '0.3' } as React.CSSProperties
                      }
                    >
                      {t('rm:rejectionReason', { note: data.note })}
                    </article>

                    {isAdvertiser() && (
                      <footer>
                        <Link to="/retail-media/audiences/create">
                          {t('rm:audience.createNew')}
                        </Link>
                      </footer>
                    )}
                  </section>
                </AlertConfirmation>
              )}
            </section>

            <hr />

            <section className="d-flex flex-column gap-2">
              <header>
                <strong>{t('rm:audience.rules')}</strong>
              </header>

              <div>
                <span
                  className={`badge text-bg-info text-white px-1 rounded-1`}
                >
                  {t('c:actions.include')}
                </span>{' '}
                <Trans
                  components={{ strong: <strong /> }}
                  //@ts-expect-error: `i18nKey` is not recognized by the `Trans` component
                  i18nKey="rm:audience.create.rules.select.description"
                />
              </div>

              {data.segmentations.map(item => (
                <section
                  key={item.id}
                  className="segmentationsWrap d-flex bg-neutral-high-light rounded"
                >
                  <div>
                    <span className="featureKey h-100 d-inline-flex bg-white border p-3 rounded-start">
                      {audienceAttributeLabels?.[item.feature_key] ||
                        item.feature_key}
                    </span>
                  </div>

                  <div className="d-flex flex-wrap p-2 gap-2 align-items-center">
                    {item.feature_values.map(value => (
                      <span className="bg-white border p-2 rounded" key={value}>
                        {value}
                      </span>
                    ))}
                  </div>
                </section>
              ))}
            </section>

            <hr />

            <section className="reach-row d-flex align-items-center gap-4">
              {
                // if data.estimated_audience is valid number
              }
              {data.estimated_audience >= 0 && (
                <article className="reach-text">
                  <strong>{t('rm:AudienceCard.text.potentialReach')}: </strong>
                  <span className="value d-inline-flex align-items-center fs-6">
                    ~ {formatNumberWithDots(data.estimated_audience)}{' '}
                    {t('c:words.person_plural')}
                    <Tooltip
                      icon={<FiHelpCircle />}
                      text={t('rm:AudienceCard.text.potentialReachDescription')}
                    />
                  </span>
                </article>
              )}

              {data?.reachRange && (
                <RangeSlider
                  min={0}
                  max={100}
                  value={data?.reachRange}
                  readOnly
                  minLabel={t('rm:AudienceCard.text.potentialReach.slider.min')}
                  maxLabel={t('rm:AudienceCard.text.potentialReach.slider.max')}
                  size="small"
                />
              )}
            </section>

            {data.status === 'approved' && (
              <>
                <hr />

                <section className="d-flex align-items-center gap-1">
                  <div className="d-flex gap-1">
                    <strong className="price-title">
                      {t('rm:AudienceCard.text.audienceCost')}:
                    </strong>
                    <span>{formatMoneyV2({ value: data.price })}</span>
                  </div>
                  <div className="d-flex align-items-center ">
                    <Badge
                      size="small"
                      template={data.price ? 'warning' : 'success'}
                      text={
                        data.price
                          ? t('rm:AudienceCard.text.audienceCost.fixedCost')
                          : t('rm:AudienceCard.text.audienceCost.noCost')
                      }
                    />
                    <Tooltip
                      icon={<FiHelpCircle />}
                      text={t('rm:AudienceCard.text.audienceCostDescription')}
                    />
                  </div>
                </section>
              </>
            )}

            {data.advertisers.length > 0 && !data.advertiser_id && (
              <>
                <hr />

                <section>
                  <header className="mb-2">
                    <strong>{t('rm:audience.visibility')}:</strong>
                    {/* <span>{tc(`rm:audience.visibility.${data.visibility}`)}</span> */}
                  </header>

                  <Table
                    data={data.advertisers}
                    schema={{
                      accountLogo: {
                        render: (rowData: AudienceAdvertiser) => (
                          <section className="d-flex align-items-center gap-2">
                            <LogoStore
                              linkLogo={rowData.account_logo}
                              backgroundLogo={
                                rowData.account_theme.primary_color
                              }
                              alt={rowData.name}
                              size="small"
                            />

                            <span>{rowData.name}</span>
                          </section>
                        )
                      }
                    }}
                    hasHeader={false}
                    striped={false}
                  />
                </section>
              </>
            )}
          </Panel>

          {data.status === 'approved' && <CampaignsByAudience />}

          <Panel>
            <strong>{t('c:terms.created_by')}</strong> {data?.user_name}{' '}
            {t('c:words.at')}{' '}
            {formatDateToString(data?.created_at, 'DD/MM/YYYY')}
          </Panel>

          {data.status === 'awaiting_approval' && isPublisher() && (
            <SectionAudienceReview audienceId={data.id} />
          )}
        </>
      )}
    </>
  )
}

export default AudienceViewPage
